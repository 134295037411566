import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export function ConfirmExportToCanvaModal({ show, loading, onHide, handleConfirm }) {
  return (
    <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton onClick={onHide}>
        <Modal.Title id='contained-modal-title-vcenter'>Export to Canva</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Do you want to export this image to Canva?</p>
        <p>
          You'll be able to create a new Canva design based on the image once it has
          finished uploading.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' disabled={loading} onClick={onHide}>
          Close
        </Button>
        <Button variant='primary' onClick={handleConfirm}>
          <span>Export</span>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
