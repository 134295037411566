import MediaContext from 'contexts/MediaContext'
import React from 'react'
import { Container } from 'react-bootstrap'
import CanvaContent from './CanvaContent'

function Canva() {
  return (
    <MediaContext>
      <Container>
        <CanvaContent />
      </Container>
    </MediaContext>
  )
}

export default Canva
