import LargeHeader from 'components/headers/LargeHeader'
import MediaUpload from 'components/media-upload/MediaUpload'
import Panel from 'components/panel/Panel'
import { AuthContext } from 'contexts/AuthContext'
import { useContext, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './CreateADigitalDisplay.module.scss'
import Tooltip from './Tooltip'

function UploadMediaToStart() {
  const cloudinaryUploadWidgetRef = useRef()
  const { user } = useContext(AuthContext)

  return (
    <Panel className={styles.create}>
      <div className={styles.createContent}>
        <LargeHeader className='mb-4'>Upload media to get started</LargeHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}>
          <MediaUpload cloudinaryUploadWidgetRef={cloudinaryUploadWidgetRef} />
          {user?.integrations?.canva?.connected && (
            <Link to='/media/canva'>
              <Tooltip label='Import media from Canva'>
                <Button style={{ width: '100%', maxWidth: '250px' }} variant='primary'>
                  Import from Canva
                </Button>
              </Tooltip>
            </Link>
          )}
        </div>
      </div>
    </Panel>
  )
}

export default UploadMediaToStart
