import { useState } from 'react'
import { ButtonGroup, Dropdown, Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'

export function CreateCanvaDesignModal({ show, loading, onHide, handleCreateDesign }) {
  const [designCreated, setDesignCreated] = useState(false)
  const [createDesignLoading, setCreateDesignLoading] = useState(false)

  const handleCreateDesignClick = (width, height) => {
    setCreateDesignLoading(true)
    handleCreateDesign(width, height, () => {
      setDesignCreated(true)
      setCreateDesignLoading(false)
    })
  }

  const handleClose = () => {
    setDesignCreated(false)
    setCreateDesignLoading(false)
    onHide()
  }

  return (
    <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title id='contained-modal-title-vcenter'>Export to Canva</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>
          {loading && !designCreated && !createDesignLoading
            ? 'Image uploading'
            : designCreated
              ? 'Design created'
              : 'Image uploaded'}
        </h4>
        {loading && !designCreated && !createDesignLoading ? (
          <p>Please wait while your image finishes uploading.</p>
        ) : designCreated ? (
          <>
            <p>
              Your design has been created in Canva and should have opened in a new tab.
            </p>
            <p>
              Once you've finished editing the design, save and close it in Canva, then
              come back to the <Link to='/media/canva'>Import from Canva</Link> screen to
              import it into Visivo.
            </p>
          </>
        ) : (
          <>
            <p>
              Your image has been uploaded to Canva. Do you want to create a new design
              with it?
            </p>
            <p>
              If you choose not to create a new design now, you'll be able to access the
              image at any time from your Canva assets library.
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' disabled={loading} onClick={handleClose}>
          Close
        </Button>
        <Dropdown as={ButtonGroup}>
          <Button
            variant='primary'
            onClick={() => handleCreateDesignClick(1920, 1080)}
            disabled={loading || designCreated}>
            {loading ? (
              <Spinner size='sm' animation='border' role='status' />
            ) : (
              <span>Create 16:9 design</span>
            )}
          </Button>

          <Dropdown.Toggle
            split
            variant='primary'
            id='dropdown-split-basic'
            disabled={loading || designCreated}
            style={{ paddingLeft: '.75rem', paddingRight: '.75rem' }}
          />

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleCreateDesignClick(1920, 540)}>
              Create 32:9 design
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleCreateDesignClick(2160, 1920)}>
              Create 9:8 design
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Footer>
    </Modal>
  )
}
