import { createDesign, exportToCanva } from 'api/canva'
import {
  deleteManyMedia,
  deleteMedia,
  setFolder,
  toggleFavourite,
  uploaded,
} from 'api/media'
import ConfirmExportToCanvaModal from 'components/ConfirmExportToCanvaModal'
import CreateFolder from 'components/create-folder/CreateFolder'
import CreateCanvaDesignModal from 'components/CreateCanvaDesignModal'
import DeleteFolder from 'components/delete-folder/DeleteFolder'
import DeleteModal from 'components/DeleteModal'
import Grid from 'components/grid/Grid'
import ListFolders from 'components/list-folders/ListFolders'
import Loader from 'components/Loader'
import LoaderButton from 'components/LoaderButton'
import MediaUpload from 'components/media-upload/MediaUpload'
import MediaItem from 'components/media/MediaItem'
import Toolbar, { ToolbarGroup } from 'components/toolbar/Toolbar'
import Tooltip from 'components/Tooltip'
import UpdateFolder from 'components/update-folder/UpdateFolder'
import UploadMediaToStart from 'components/UploadMediaToStart'
import UsageBanner from 'components/UsageBanner'
import { AuthContext } from 'contexts/AuthContext'
import { CloudinaryUploadWidgetContext } from 'contexts/CloudinaryUploadWidgetContext'
import { MediaContext } from 'contexts/MediaContext'
import React, { useContext, useState } from 'react'
import { Badge, Button, Image, Spinner } from 'react-bootstrap'
import {
  ArrowLeftCircle,
  Check,
  House,
  Shuffle,
  Star,
  StarFill,
  Trash,
} from 'react-bootstrap-icons'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import styles from './MediaList.module.scss'

const maxSelections = 50

function Media() {
  const { state, dispatch, loadMore, reloadMeta } = useContext(MediaContext)
  const [loadingMore, setLoadingMore] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')
  const [selected, setSelected] = useState([])
  const { openUploadWidget } = useContext(CloudinaryUploadWidgetContext)
  const { user } = useContext(AuthContext)

  const handleLoadMore = async () => {
    setLoadingMore(true)
    await loadMore()
    setLoadingMore(false)
  }

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deleteMedia(id)

    if (success) {
      dispatch({ type: 'REMOVE_FROM_DATA', payload: index })
      reloadMeta()
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setDeleting(false)
  }

  const handleDeleteMany = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    await deleteManyMedia({ ids: selected.map(x => x.id) })
    const indexes = selected.map(x => state.data.indexOf(x))
    const newData = state.data.filter((_, i) => !indexes.includes(i))
    dispatch({ type: 'SET_DATA', payload: newData })
    dispatch({ type: 'SET_LOADING', payload: false })
    setSelected([])
  }

  const handleClickReplace = (id, index) => {
    openUploadWidget((error, result) => {
      if (!error && result && result.event === 'success') {
        uploaded({
          existing_id: id,
          asset_id: result.info.asset_id,
          public_id: result.info.public_id,
          path: result.info.path,
          url: result.info.url,
          thumbnail_url: result.info.thumbnail_url,
          original_filename: result.info.original_filename,
          resource_type: result.info.resource_type,
          format: result.info.format,
          width: result.info.width,
          height: result.info.height,
          bytes: result.info.bytes,
        }).then(res => {
          dispatch({
            type: 'REPLACE_IN_DATA',
            payload: {
              index: index,
              item: {
                id: res.data.id,
                resource_type: res.data.resource_type,
                public_id: res.data.public_id,
                filename: res.data.filename,
              },
            },
          })
          reloadMeta()
        })
      }
    })
  }

  const handleSelect = resource => {
    if (selected.length < maxSelections) {
      const newSelected = Array.from(selected)
      const index = newSelected.indexOf(resource)
      index === -1 ? newSelected.push(resource) : newSelected.splice(index, 1)
      setSelected(newSelected)
    }
  }

  if (state.loading) {
    return <Loader />
  }

  if (
    state.data.length === 0 &&
    state.meta.folders.length === 0 &&
    state.meta.folder.id === ''
  ) {
    return (
      <div style={{ maxWidth: '400px', margin: 'auto' }}>
        <UploadMediaToStart />
      </div>
    )
  }

  return (
    <React.Fragment>
      <MediaToolbar
        parentFolderId={
          state.meta.folder.id !== '' ? state.meta.parent_folder.id : undefined
        }
        selected={selected}
        setSelected={setSelected}
        onDelete={handleDeleteMany}
      />

      <UsageBanner
        used={`${Math.round(state.meta.usage.used)}MB`}
        limit={`${state.meta.usage.limit}MB`}
        exceededLimit={Math.round(state.meta.usage.used) >= state.meta.usage.limit}>
        {' '}
        storage used
      </UsageBanner>

      {state.meta.child_folders.length === 0 && state.data.length === 0 && (
        <div className='text-center m-5'>No media items found</div>
      )}

      <ListFolders
        folders={state.meta.child_folders.map(x => {
          x.otherProps = { as: Link, to: `/media/${x.id}` }
          return x
        })}
      />

      <div style={{ minHeight: '80vh' }}>
        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={state.data.length}
          next={handleLoadMore}
          hasMore={state.links.next}>
          <Grid>
            {state.data.map((x, index) => (
              <MediaItem
                key={`${x.public_id}-${index}`}
                selected={selected.includes(x)}
                onSelect={selected.length > 0 ? handleSelect : undefined}
                resource={x}
                orientation={x.width < x.height ? 'portrait' : 'landscape'}
                overlay={
                  <>
                    {selected.length <= 0 && (
                      <div className={styles.overlay}>
                        <Tooltip label='Select item'>
                          <Button
                            variant='primary'
                            className='mr-1 px-2'
                            onClick={() => handleSelect(x)}>
                            <Check />
                          </Button>
                        </Tooltip>

                        <Favourite id={x.id} index={index} isFavourited={x.favourite} />

                        <Tooltip label='Replace media'>
                          <Button
                            variant='primary'
                            className='mr-1 px-2'
                            onClick={() => {
                              handleClickReplace(x.id, index)
                            }}>
                            <Shuffle />
                          </Button>
                        </Tooltip>

                        {user?.integrations?.canva?.connected && (
                          <ExportToCanva id={x.id} />
                        )}

                        {x.is_in_use ? (
                          <Tooltip label='Media in use by playlist'>
                            <Button variant='danger' className='mr-1 px-2' disabled>
                              <Trash />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            variant='danger'
                            className='mr-1 px-2'
                            onClick={() => handleOpenDeleteDialog(x.id, index)}>
                            <Trash />
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                }
              />
            ))}
          </Grid>

          {state.links.next && (
            <div className='text-center mt-5'>
              <LoaderButton
                label='Load More'
                onClick={handleLoadMore}
                loading={loadingMore}
                variant='success'
              />
            </div>
          )}
        </InfiniteScroll>
      </div>

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />
    </React.Fragment>
  )
}

function Favourite({ id, index, isFavourited }) {
  const { dispatch } = useContext(MediaContext)
  const [favouriting, setFavouriting] = useState(false)

  const handleToggleFavourite = async (id, index) => {
    setFavouriting(true)
    const success = await toggleFavourite(id)
    if (success) {
      dispatch({ type: 'TOGGLE_FAVOURITE', payload: index })
    }
    setFavouriting(false)
  }

  return (
    <Tooltip label={isFavourited ? 'Unfavourite' : 'Favourite'}>
      <Button
        disabled={favouriting}
        variant='primary'
        className='mr-1 px-2'
        onClick={() => {
          handleToggleFavourite(id, index)
        }}>
        {favouriting ? (
          <Spinner as='span' animation='border' size='sm' />
        ) : isFavourited ? (
          <StarFill />
        ) : (
          <Star />
        )}
      </Button>
    </Tooltip>
  )
}

function ExportToCanva({ id }) {
  const [loading, setLoading] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [createDesignModal, setCreateDesignModal] = useState(false)
  const [canvaAssetId, setCanvaAssetId] = useState()

  const handleConfirmExportToCanva = () => {
    setConfirmModal(true)
  }

  const handleExportToCanva = async id => {
    setLoading(true)
    setConfirmModal(false)
    setCreateDesignModal(true)
    try {
      const data = await exportToCanva(id)
      setCanvaAssetId(data.assetId)
    } catch (e) {
      console.log('Error exporting to canva: ', e)
    }
    setLoading(false)
  }

  const handleCreateDesign = async (width = 1920, height = 1080, successCallback) => {
    setLoading(true)
    try {
      const data = await createDesign(canvaAssetId, width, height)
      if (data.editUrl) {
        window.open(data.editUrl, '_blank').focus()
        successCallback()
      }
    } catch (e) {
      console.log('Error creating a design in Canva: ', e)
    }
    setLoading(false)
  }

  return (
    <>
      <Tooltip label='Export to Canva'>
        <Button
          disabled={loading}
          variant='primary'
          className='mr-1 px-2'
          style={{ width: '35px' }}
          onClick={handleConfirmExportToCanva}>
          {loading ? (
            <Spinner as='span' animation='border' size='sm' />
          ) : (
            <Image src='/canva-c-small.png' style={{ width: '12px', height: '16px' }} />
          )}
        </Button>
      </Tooltip>
      <ConfirmExportToCanvaModal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        handleConfirm={() => {
          setConfirmModal(false)
          handleExportToCanva(id)
        }}
      />
      <CreateCanvaDesignModal
        show={createDesignModal}
        loading={loading}
        onHide={() => setCreateDesignModal(false)}
        handleCreateDesign={handleCreateDesign}
      />
    </>
  )
}

function MediaToolbar({ parentFolderId, selected, setSelected, onDelete }) {
  const { state, dispatch, reloadMeta } = useContext(MediaContext)
  const { user } = useContext(AuthContext)

  const handleMove = folderId => {
    setFolder({ media: selected.map(x => x.id), folder_id: folderId })
    const newData = state.data.filter((x, i) => state.data.indexOf(x) !== i)
    dispatch({ type: 'SET_DATA', payload: newData })
    setSelected([])
  }

  return (
    <Toolbar>
      <ToolbarGroup>
        <div className='mr-1 mb-1'>
          <MediaUpload folderId={state.meta.folder.id} />
        </div>
        {user?.integrations?.canva?.connected && (
          <div className='mr-1 mb-1'>
            <Link to='/media/canva'>
              <Tooltip label='Import media from Canva'>
                <Image src='/canva-import-button.png' />
              </Tooltip>
            </Link>
          </div>
        )}

        {parentFolderId !== undefined && (
          <>
            <Tooltip label='Media Home'>
              <Button as={Link} to='/media' variant='primary' className='mr-1 px-2 mb-1'>
                <House />
              </Button>
            </Tooltip>

            <Tooltip label='Back'>
              <Button
                as={Link}
                to={`/media/${parentFolderId}`}
                variant='primary'
                className='mr-1 px-2 mb-1'>
                <ArrowLeftCircle />
              </Button>
            </Tooltip>
          </>
        )}
      </ToolbarGroup>

      <ToolbarGroup>
        {state.meta.folder.id !== '' && (
          <Badge variant='secondary' className='mr-1 px-2 d-flex align-items-center mb-1'>
            {state.meta.folder.name}
          </Badge>
        )}

        <CreateFolder
          type='MEDIA'
          parentFolderId={state.meta.folder.id}
          onSuccess={reloadMeta}
        />

        {state.meta.folder.id !== '' && (
          <>
            <UpdateFolder
              folderId={state.meta.folder.id}
              defaultName={state.meta.folder.name}
              onSuccess={reloadMeta}
            />

            <DeleteFolder
              folderId={state.meta.folder.id}
              name={state.meta.folder.name}
              onSuccess={reloadMeta}
            />
          </>
        )}
      </ToolbarGroup>

      {selected.length > 0 && (
        <ToolbarGroup>
          <DropdownButton
            className='mr-1 mb-1'
            name='display-images-for'
            title={<>Move {selected.length} items</>}>
            {state.meta.folders.map(x => (
              <Dropdown.Item key={x.id} onClick={() => handleMove(x.id)}>
                {x.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>

          {selected.filter(x => x.is_in_use).length === 0 && (
            <Button variant='danger' className='mr-1 mb-1' onClick={onDelete}>
              Delete {selected.length} items
            </Button>
          )}
        </ToolbarGroup>
      )}
    </Toolbar>
  )
}

export default Media
