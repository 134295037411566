import { importDesign, listDesigns } from 'api/canva'
import CanvaMediaItem from 'components/canva/CanvaMediaItem'
import Grid from 'components/grid/Grid'
import Loader from 'components/Loader'
import LoaderButton from 'components/LoaderButton'
import Tooltip from 'components/Tooltip'
import { default as React, useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { ArrowDownSquare, CheckCircle } from 'react-bootstrap-icons'
import Spinner from 'react-bootstrap/Spinner'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import styles from './CanvaDesignsList.module.scss'

function CanvaDesignsList() {
  const [continuation, setContinuation] = useState('')
  const [designs, setDesigns] = useState([])
  const [importedDesigns, setImportedDesigns] = useState([])
  const [importing, setImporting] = useState()
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const { addToast } = useToasts()

  const getDesigns = useCallback(async () => {
    setLoadingMore(true)
    const data = await listDesigns(continuation)
    setDesigns(prevDesigns => prevDesigns.concat(data?.items))
    setContinuation(data?.continuation ? data.continuation : '')
    setLoading(false)
    setLoadingMore(false)
  }, [continuation])

  useEffect(() => {
    getDesigns()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleImport = async id => {
    setImporting(id)
    const res = await importDesign(id)
    if (res.ok) {
      addToast(
        'Import started. The image will be available in your media library once the import finishes.',
        { appearance: 'success' }
      )
      setImportedDesigns(prevImported => [...prevImported, id])
      setImporting()
    } else {
      addToast('Failed to import design', { appearance: 'warning' })
      setImporting()
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <div style={{ minHeight: '80vh' }}>
        {!designs?.length > 0 && !loading ? (
          <div>
            <p>You don't have any designs available to import from Canva.</p>
            <p>
              You could create a new design by exporting{' '}
              <Link to='/media'>an existing image</Link> to Canva.
            </p>
          </div>
        ) : (
          <InfiniteScroll
            style={{ overflow: 'visible' }}
            dataLength={designs?.length}
            next={getDesigns}
            hasMore={continuation}>
            <Grid>
              {designs?.map(x => (
                <CanvaMediaItem
                  key={x.id}
                  resource={x}
                  orientation={
                    x?.thumbnail?.width < x?.thumbnail?.height ? 'portrait' : 'landscape'
                  }
                  overlay={
                    <>
                      <div className={styles.overlay}>
                        <Tooltip label='Import design'>
                          <Button
                            variant='primary'
                            className='mr-1 px-2'
                            disabled={
                              importing === x.id || importedDesigns.includes(x.id)
                            }
                            onClick={() => handleImport(x.id)}>
                            {importing === x.id ? (
                              <Spinner animation='border' size='sm' />
                            ) : importedDesigns.includes(x.id) ? (
                              <CheckCircle />
                            ) : (
                              <ArrowDownSquare />
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    </>
                  }
                />
              ))}
            </Grid>
            {continuation && (
              <div className='text-center mt-5'>
                <LoaderButton
                  label='Load More'
                  onClick={getDesigns}
                  loading={loadingMore}
                  variant='success'
                />
              </div>
            )}
          </InfiniteScroll>
        )}
      </div>
    </React.Fragment>
  )
}

export default CanvaDesignsList
