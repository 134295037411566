import { handleCallback } from 'api/canva'
import Loader from 'components/Loader'
import { useEffect, useState } from 'react'

const CanvaCallback = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleCallback()
        if (res?.url) {
          window.location.href = res?.url
        }

        if (res?.message) {
          setError(res?.message)
        }

        window.location.href = '/my-profile'
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return null
}

export default CanvaCallback
