import React from 'react'
import styles from './LargeHeader.module.scss'

function LargeHeader({ children, className }) {
  return (
    <div className={[styles.header, className].filter(Boolean).join(' ')}>{children}</div>
  )
}

export default LargeHeader
