import PropTypes from 'prop-types'
import styles from './CanvaMediaItem.module.scss'

function CanvaMediaItem({ resource, resourceOverlay, overlay }) {
  return (
    <div className={`${'d-flex flex-grow-1'}`}>
      <div style={{ position: 'relative', display: 'flex', flexGrow: '1' }}>
        <img
          src={resource?.thumbnail?.url}
          alt='media'
          className={`${styles.itemContent}`}
        />

        {resourceOverlay && (
          <img
            src={resource.url}
            style={{ position: 'absolute', top: 0, left: 0 }}
            alt='media overlay'
            className={`${styles.itemContent}`}></img>
        )}

        {overlay && <div className={styles.overlay}>{overlay}</div>}
      </div>
    </div>
  )
}

CanvaMediaItem.propTypes = {
  resource: PropTypes.object,
  orientation: PropTypes.string,
  aspectRatio: PropTypes.string,
  overlay: PropTypes.node,
}

CanvaMediaItem.defaultProps = {
  orientation: 'landscape',
  aspectRatio: '16:9',
}

export default CanvaMediaItem
