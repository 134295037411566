import {
  disableTwoFactorAuth,
  enableTwoFactorAuth,
  me,
  passwordConfirm,
  twoFactorAuthQrCode,
  twoFactorAuthRecoveryCodes,
  updateMe,
} from 'api/auth'
import { connectToCanva, disconnectFromCanva } from 'api/canva'
import LargeHeader from 'components/headers/LargeHeader'
import SmallHeader from 'components/headers/SmallHeader'
import Loader from 'components/Loader'
import LoaderButton from 'components/LoaderButton'
import Panel from 'components/panel/Panel'
import { AuthContext } from 'contexts/AuthContext'
import { useContext, useEffect, useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import { useToasts } from 'react-toast-notifications'

function MyProfile(props) {
  const [user, setUser] = useState()
  const [updating, setUpdating] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [passwordConfirmed, setPasswordConfirmed] = useState(false)
  const { addToast } = useToasts()
  const { fetchData } = useContext(AuthContext)

  useEffect(() => {
    me().then(res => setUser(res))
    setUpdated(false)
  }, [updated])

  const handleUpdateNotifications = async e => {
    setUpdating(true)
    setUser(user => ({ ...user, device_offline_notifications: e.target.checked }))
    await updateMe({ device_offline_notifications: e.target.checked })
    setUpdating(false)
    addToast('Notification settings updated', { appearance: 'success' })
  }

  const handleCanvaConnection = async connect => {
    try {
      if (connect) {
        await connectToCanva()
      } else {
        const res = await disconnectFromCanva()
        if (res.success) {
          await fetchData()
          setUpdated(true)
          addToast('Canva account disconnected', { appearance: 'success' })
        } else {
          addToast('There was a problem disconnecting from Canva', {
            appearance: 'warn',
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  if (!user) return <Loader />

  return (
    <Container>
      <LargeHeader>My Profile</LargeHeader>

      <Panel className='mb-4'>
        <div>
          <strong>Name</strong>: {user.first_name} {user.last_name}
        </div>
        <div>
          <strong>Email</strong>: {user.email}
        </div>
      </Panel>

      {user.show_device_statuses && (
        <Panel className='mb-4'>
          <SmallHeader>Notfications</SmallHeader>
          <div className='d-flex justify-content-between align-items-center'>
            <Form.Check
              type='checkbox'
              label={`Notify me via email when a display is offline.`}
              onChange={handleUpdateNotifications}
              checked={user.device_offline_notifications}
            />
            {updating && <Spinner animation='border' size='sm' />}
          </div>
        </Panel>
      )}

      <Panel className='mb-4'>
        <SmallHeader>Two Factor Authentication (2FA)</SmallHeader>

        {passwordConfirmed ? (
          <>
            {user.two_factor ? (
              <DisableTwoFactorForm setUser={setUser} />
            ) : (
              <EnableTwoFactorForm />
            )}
          </>
        ) : (
          <PasswordConfirmForm onConfirmed={() => setPasswordConfirmed(true)} />
        )}
      </Panel>

      {user?.integrations?.canva?.enabled && (
        <Panel>
          <SmallHeader>Integrations</SmallHeader>
          {!user?.integrations?.canva?.connected ? (
            <Image
              src='/connect-to-canva.png'
              style={{ cursor: 'pointer' }}
              onClick={() => handleCanvaConnection(true)}
            />
          ) : (
            <Button variant='danger' onClick={() => handleCanvaConnection(false)}>
              Disconnect Canva
            </Button>
          )}
        </Panel>
      )}
    </Container>
  )
}

function PasswordConfirmForm({ onConfirmed }) {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const [password, setPassword] = useState('')

  const handleChange = e => setPassword(e.target.value)

  const handleSubmit = async e => {
    e.preventDefault()

    setSubmitting(true)
    setError(false)
    const success = await passwordConfirm(password)

    if (success) {
      onConfirmed()
    } else {
      setError(true)
    }

    setSubmitting(false)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <p>Before you can access 2FA settings, please confirm your password.</p>

      <Form.Group className='mb-4'>
        <Form.Group>
          <Form.Control
            type='password'
            name='password'
            placeholder='Enter your password'
            onChange={handleChange}
          />
        </Form.Group>
      </Form.Group>

      <LoaderButton
        label='Submit'
        className='mb-4'
        variant='primary'
        loading={submitting}
        onClick={handleSubmit}
        type='submit'
      />

      {error && (
        <Alert className='mt-4' variant='danger'>
          Failed to confirm your password
        </Alert>
      )}
    </Form>
  )
}

function EnableTwoFactorForm() {
  const [enabling, setEnabling] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [error, setError] = useState(false)
  const [qrCode, setQrCode] = useState()
  const [recoveryCodes, setRecoveryCodes] = useState()

  const { setTwoFactorAuthBanner } = useContext(AuthContext)

  const handleEnable = async () => {
    setEnabling(true)
    setError(false)
    const success = await enableTwoFactorAuth()

    if (success) {
      setQrCode(await twoFactorAuthQrCode())
      setRecoveryCodes(await twoFactorAuthRecoveryCodes())
      setEnabled(true)
      setTwoFactorAuthBanner(false)
    } else {
      setError(true)
    }

    setEnabling(false)
  }

  if (enabled) {
    return (
      <div>
        <p>Scan the below QR code with your authenticator app.</p>
        <div dangerouslySetInnerHTML={{ __html: qrCode.svg }} />

        <div className='mt-4'>
          <strong>Recovery Codes</strong>
        </div>

        <p>
          Keep an a note of these recovery codes in case you lose access to your
          authenticator app.
        </p>

        {recoveryCodes.map((x, i) => (
          <div key={i}>{x}</div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <p>
        2FA provides an extra layer of security by requiring an additional code when
        logging in.
      </p>

      <p>
        You will first need to install a 2FA app on your mobile devices. For example,
        Google Authenticator.
      </p>

      <p>
        When you click the Enable 2FA button below you will be shown a QR code, you will
        need to scan this with your app.
      </p>

      <p>
        When you next login will be prompted for your 2FA code which will be available on
        your authenticator app.
      </p>

      <p>
        You will also be shown recovery codes, make a note of these. Recovery codes can be
        used instead of using a 2FA code, in cases where you can't access or have lost
        your mobile device. Each recovery code can only be used once.
      </p>

      <LoaderButton
        label='Enable 2FA'
        className='mb-4 mt-4'
        variant='primary'
        loading={enabling}
        onClick={handleEnable}
      />

      {error && (
        <Alert className='mt-4' variant='danger'>
          Failed to enable two factor authentication
        </Alert>
      )}
    </div>
  )
}

function DisableTwoFactorForm({ setUser }) {
  const [disabling, setDisabling] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [error, setError] = useState(false)

  const { setTwoFactorAuthBanner } = useContext(AuthContext)

  const handleDisable = async () => {
    setDisabling(true)
    setError(false)
    const success = await disableTwoFactorAuth()
    if (success) {
      setUser(await me())
      setTwoFactorAuthBanner(true)
    } else {
      setError(true)
    }
    setDisabling(false)
  }

  if (confirm) {
    return (
      <div>
        <p>
          <strong>Are you sure you want disable two factor authentication?</strong>
        </p>

        <LoaderButton
          label='Yes, Disable 2FA Now'
          className='mb-4 mt-4'
          variant='danger'
          loading={disabling}
          onClick={handleDisable}
        />

        {error && (
          <Alert className='mt-4' variant='danger'>
            Failed to disable two factor authentication
          </Alert>
        )}
      </div>
    )
  }

  return (
    <div>
      <p>
        You current have two factor authentication enabled, this means that when you login
        you will be prompted for a code.
      </p>

      <p>
        You will need to use an mobile phone authenticate app such as Google Authenticator
        to retrieve your login code.
      </p>

      <LoaderButton
        label='Disable 2FA'
        className='mb-4 mt-4'
        variant='primary'
        onClick={() => setConfirm(true)}
      />
    </div>
  )
}

export default MyProfile
