import LargeHeader from 'components/headers/LargeHeader'
import React from 'react'
import CanvaDesignsList from './CanvaDesignsList'

function CanvaContent() {
  return (
    <React.Fragment>
      <LargeHeader>Canva Designs</LargeHeader>
      <CanvaDesignsList />
    </React.Fragment>
  )
}

export default CanvaContent
