import config from 'config'
import { getJsonHeaders } from './helpers'

export async function connectToCanva() {
  try {
    const response = await fetch(config.API_URL + `/iapi/integrations/canva/authorize`, {
      method: 'GET',
      headers: await getJsonHeaders(),
      credentials: 'include',
    })

    const data = await response.json()

    if (data.authUrl) {
      window.location.href = data.authUrl
    }
  } catch (e) {
    throw e
  }
}

export async function disconnectFromCanva() {
  try {
    const response = await fetch(config.API_URL + `/iapi/integrations/canva/disconnect`, {
      method: 'GET',
      headers: await getJsonHeaders(),
      credentials: 'include',
    })

    if (response.ok) {
      const data = await response.json()
      return { success: true, data }
    }

    throw new Error('Failed to disconnect from Canva')
  } catch (e) {
    return { success: false, data: { message: e } }
  }
}

export async function handleCallback() {
  try {
    const urlParams = new URLSearchParams(window.location.search)
    if (!urlParams.has('code')) {
      return (window.location.href = '/my-profile')
    }

    const response = await fetch(
      config.API_URL + `/iapi/integrations/canva/callback${window.location.search}`,
      {
        method: 'GET',
        headers: await getJsonHeaders(),
        credentials: 'include',
      }
    )

    const contentType = response.headers.get('content-type')
    if (contentType?.includes('application/json')) {
      const data = await response.json()
      return { success: true, message: data?.message }
    }
  } catch (e) {
    return { success: false, message: e }
  }
}

export async function listDesigns(continuation) {
  try {
    const response = await fetch(
      config.API_URL +
        `/iapi/integrations/canva/designs/list?continuation=${continuation}`,
      {
        method: 'GET',
        headers: await getJsonHeaders(),
        credentials: 'include',
      }
    )

    return await response.json()
  } catch (e) {
    throw e
  }
}

export async function importDesign(id) {
  try {
    const response = await fetch(
      config.API_URL + `/iapi/integrations/canva/designs/import`,
      {
        method: 'POST',
        headers: await getJsonHeaders(),
        credentials: 'include',
        body: JSON.stringify({ id: id }),
      }
    )
    return response
  } catch (e) {
    throw e
  }
}

export async function createDesign(id, width, height) {
  try {
    const response = await fetch(
      config.API_URL + `/iapi/integrations/canva/designs/create`,
      {
        method: 'POST',
        headers: await getJsonHeaders(),
        credentials: 'include',
        body: JSON.stringify({ id: id, width: width, height: height }),
      }
    )

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`)
    }

    const data = await response.json()
    return data
  } catch (e) {
    throw e
  }
}

export async function exportToCanva(id) {
  try {
    const res = await fetch(
      config.API_URL + `/iapi/integrations/canva/assets/import/cloudinary`,
      {
        method: 'POST',
        headers: await getJsonHeaders(),
        credentials: 'include',
        body: JSON.stringify({ id: id }),
      }
    )

    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`)
    }

    const data = await res.json()
    return data
  } catch (e) {
    throw e
  }
}
